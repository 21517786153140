.footer-container {
    background-color: transparent;
  
    color: white;
  }
  

  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: black;
   font-family: 'Poppins',sans-serif;
    
    
  }
  
  .footer-column {
    
    flex: 1 1 20%;
    padding: 10px;
    
    
  }
  
  .footer-logo {
    width: 100px;
    margin-bottom: 10px;
  }
  
  .subscription-form {
    display: flex;
    flex-direction: column;
  }
  
  .subscription-form input {
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
  }
  
  .subscription-form button {
    padding: 10px;
    background-color: #ff5722;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .subscription-form button:hover {
    background-color: #e64a19;
  }
  
  .footer-column h3 {
    margin-bottom: 15px;
    color:black;
  }
  
  .footer-column a {
    color: black;
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
  }
  
  .footer-column a:hover {
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .footer-column {
      flex: 1 1 100%;
    }
  }
  .spanclass{
    font-weight: 600;
    
  }
  .emailclass{
    margin-top: 15px;
  }