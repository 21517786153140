.row2211{
  padding-left:200px;
  padding-right: 200px;
  margin-top:60px
}
@media(max-width:768px){
  .row2211{
    padding-left:10px;
    padding-right: 10px;
    margin-top:40px
  }
  
  .contact-get-started-section{
    margin-top: 10px;
    padding-left:0px;
    padding-right: 0px;
  }
 

}
.button_contact{
  background-color: black;
  color:white;
  border-radius: 40px;
  width:150px;
  margin-bottom:20px;
  margin-top: 20px;
}



.contact-get-started-section {
  margin-top: 100px;
  padding-left: 100px;
  padding-right: 80px;
}

.contact-get-started-section h1 {
  margin-top: 30px;
  font-size: 3rem;
  font-weight: 100;
  font-family: 'Poppins', sans-serif;
}

.contact-get-started-section h3 {
  margin-top: 30px;
  font-size: 25px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
  .contact-get-started-section {
    padding-left: 20px; 
    padding-right: 20px;
  }

  .contact-get-started-section h1 {
    font-size: 2rem; 
  }

  .contact-get-started-section h3 {
    font-size: 18px; 
  }
}

@media (max-width: 576px) {
  .contact-get-started-section {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 100px; 
  }

  .contact-get-started-section h1 {
    font-size: 1.5rem; 
  }

  .contact-get-started-section h3 {
    font-size: 16px; 
  }

  .background-image-container {
    display: none; /* Continue to hide the image for smaller screens */
  }
}





/* Background Image Container */
.background-image-container {
  position: relative;
  width: 100%;
  height: 150px;
  margin-top: 20px;
  overflow: hidden;
  padding-left:70px;
  padding-right: 70px;
}

.background-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  border-radius: 20px;
  
}