.terms-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .terms-heading {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .terms-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
  }
  
  .terms-subheading {
    font-size: 18px;
    font-weight: bold;
    flex-basis: 30%;
    margin-right: 10px;
    text-align: left;
  }
  
  .terms-paragraph {
    font-size: 16px;
    line-height: 1.6;
    flex-basis: 70%;
    text-align: left;
  }
  
  @media (max-width: 768px) {
    .terms-section {
      display: block;
      margin-bottom: 20px;
    }
  
    .terms-subheading {
      margin-bottom: 10px;
    }
  }
  